<template>
  <div>
    <div class="member-photo text-center" v-if="currentUser !== null">
      <member-avatar :src="currentUser.avatar"></member-avatar>
    </div>
    <el-form :rules="schema" ref="myFormRef" :model="currentUser" v-if="currentUser !== null">
      <div class="member-form">
        <div class="container">
          <el-form-item :label="$t('PROFILE.NAME')" prop="name" class="form-group">
            <el-input
              type="text"
              name="name"
              v-model="currentUser.name"
              :placeholder="$t('FORM.ENTRY', { field: $t('PROFILE.NAME') })"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`${$t('PROFILE.BIRTHDAY')} (完成設定後不能再次修改)`"
            prop="birthday"
            class="form-group"
          >
            <el-date-picker
              v-model="currentUser.birthday"
              :placeholder="$t('FORM.PICK', { field: $t('PROFILE.BIRTHDAY') })"
              type="date"
              :disabled="(firstBirthday !== null)"
            ></el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('PROFILE.GENDER')" prop="gender" class="form-group">
            <el-radio-group v-model="currentUser.gender" size="medium">
              <el-radio-button label="1" v-model="currentUser.gender">
                {{
                $t("PROFILE.SEX.MALE")
                }}
              </el-radio-button>
              <el-radio-button label="0" v-model="currentUser.gender">
                {{
                $t("PROFILE.SEX.FEMALE")
                }}
              </el-radio-button>
              <el-radio-button label="2" v-model="currentUser.gender">
                {{
                $t("PROFILE.SEX.OTHER")
                }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('PROFILE.EMAIL')" prop="email" class="form-group">
            <el-input
              v-model="currentUser.email"
              :placeholder="$t('FORM.ENTRY', { field: $t('PROFILE.EMAIL') })"
              type="text"
            ></el-input>
          </el-form-item>

          <el-form-item :label="$t('PROFILE.MOBILE')" prop="mobile_phone" class="form-group">
            <el-input
              v-model="currentUser.mobile_phone"
              :placeholder="$t('FORM.ENTRY', { field: $t('PROFILE.MOBILE') })"
              type="tel"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('PROFILE.ADDRESS')"
            prop="address"
            class="form-group container p-0"
          >
            <div class="row row-cols-2 mb-2">
              <cascader-cites
                :cityId="defaultAddress.city_id"
                :cityareaId="defaultAddress.cityarea_id"
                @change="setProfileSelect"
              ></cascader-cites>
            </div>
            <div class="row">
              <div class="col">
                <el-input
                  v-model="defaultAddress.address"
                  :placeholder="
                    $t('FORM.ENTRY', { field: $t('PROFILE.ADDRESS') })
                  "
                  type="text"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <!--收件人地址 ED-->
          <el-form-item label="公司統編" prop="vat_number" class="form-group">
            <el-input v-model="currentUser.vat_number" placeholder="請輸入公司統編" type="tel"></el-input>
          </el-form-item>
          <el-form-item label="手機條碼載具" prop="invoice_carrier" class="form-group">
            <el-input v-model="currentUser.invoice_carrier" placeholder="請輸入手機條碼載具"></el-input>
          </el-form-item>
          <el-form-item label="愛心碼" prop="npo_ban" class="form-group">
            <el-input v-model="currentUser.npo_ban" placeholder="請輸入愛心碼" type="tel"></el-input>
          </el-form-item>

          <el-row :gutter="22">
            <el-col :span="12">
              <el-button
                style="width: 100%;background-color: #909399;border-color: #909399;"
                width="100%"
                type="info"
                @click="handleGoHome()"
              >回會員中心</el-button>
            </el-col>
            <el-col :span="12">
              <el-button
                style="width: 100%;background-color: #FD7E14;border-color: #FD7E14;"
                width="100%"
                type="primary"
                @click="onSubmit('myFormRef')"
                v-if="!isUpdating"
              >{{ $t("FORM.SAVE") }}</el-button>
              <el-button
                style="width: 100%;background-color: #FD7E14;border-color: #FD7E14;"
                width="100%"
                type="primary"
                disabled
                v-if="isUpdating"
              >{{ $t("FORM.SAVE") }}</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
  </div>
</template>
<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-form-item__label {
  float: none;
}
.row-cols-2 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.el-cascader {
  width: 100%;
}
</style>
<script>
import MemberAvatar from "@/components/MemberAvatar";
import CascaderCites from "@/components/CascaderCites";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";

export default {
  name: "Profile",
  components: {
    MemberAvatar,
    CascaderCites,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    let firstBirthday = ref("");
    store
      .dispatch("member/getProfile")
      .then(() => {
        console.log(currentUser.value);
        firstBirthday.value = currentUser.value.birthday;
      })
      .catch(() => {
        router.push("/login");
      }); //取資料

    const currentUser = computed(() => store.state.member.profile);
    const isUpdating = computed(() => store.state.member.isUpdating);
    const updatedData = computed(() => store.state.member.updatedData);
    const defaultAddress = computed(() => {
      store.dispatch("member/getDefaultAddress");
      return store.state.member.defaultAddress;
    });
    // Define a validation schema
    const schema = {
      name: [
        {
          required: true,
          trigger: "blur",
          message: "請輸入姓名",
        },
      ],
      birthday: [
        {
          required: true,
          trigger: "blur",
          type: "date",
          message: "請選擇生日",
        },
      ],
      gender: [
        {
          required: true,
          trigger: "blur",
          message: "請選擇性別",
        },
      ],
      email: [
        {
          required: true,
          trigger: "blur",
          message: "請輸入電子郵件",
        },
        {
          type: "email",
          message: "請檢查電子郵件格式是否正確",
          trigger: "blur",
        },
      ],
      mobile_phone: [
        {
          type: "string",
          pattern: /^[0-9]{10}$/,
          len: 10,
          required: true,
          trigger: "blur",
          message: "請輸入正確手機號碼（10碼）",
        },
      ],
      vat_number: [
        {
          len: 8,
          type: "string",
          pattern: /^[0-9]{8}$/,
          required: false,
          trigger: "blur",
          message: "公司統編格式錯誤(8碼數字）",
        },
      ],
      npo_ban: [
        {
          min: 3,
          max: 7,
          pattern: /^[0-9]+$/,
          type: "string",
          required: false,
          trigger: "blur",
          message: "愛心碼格式錯誤（3-7碼數字）",
        },
      ],
      invoice_carrier: [
        {
          type: "string",
          pattern: /^\/{1}[0-9A-Z+-.]{7}$/,
          required: false,
          trigger: "blur",
          message: "手機載具格式錯誤",
        },
      ],
    };

    //methon
    const setProfileSelect = (value) => {
      // console.log(value)
      let keys = ["city_id", "cityarea_id"];
      let e = {};
      keys.forEach(function (key, val) {
        e = {
          target: {
            name: key,
            value: value ? value[val] : 0,
          },
        };
        store.dispatch("member/updateDefaultAddressInput", e);
      });
    };
    const handleGoHome = ()=>{
      router.push('/member')
    }
    const myFormRef = ref();
    const onSubmit = () => {
      myFormRef.value
        .validate()
        .then(() => {
          let { birthday } = store.state.member.profile;
          if (firstBirthday.value == null && birthday !== null) {
            Swal.fire({
              // title: '生日送出後不能再次修改',
              html: "生日完成設定後不能再次修改，<br />是否確認送出",
              icon: "warning",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonColor: "#fd7e14",
              // confirmButtonClass: "btn btn-block btn-main shadow text-white my-2",
              confirmButtonText: "確認",
              cancelButtonText: "取消",
            }).then((value) => {
              if (value.isConfirmed) {
                sendData();
              }
            });
          } else {
            sendData();
          }
        })
        .catch((err) => {
          console.log(err);
          onAlert("error");
        });
    };
    const sendData = () => {
      let {
        name,
        gender,
        birthday,
        email,
        mobile_phone,
        invoice_carrier,
        vat_number,
        npo_ban,
      } = store.state.member.profile;
      let { city_id, cityarea_id, address } = store.state.member.defaultAddress;
      store.dispatch("member/updateProfile", {
        name: name,
        gender: gender,
        birthday: birthday,
        email: email,
        mobile_phone: mobile_phone,
        city_id: city_id || 0,
        cityarea_id: cityarea_id || 0,
        address: address,
        vat_number: vat_number,
        npo_ban: npo_ban,
        invoice_carrier: invoice_carrier,
      });
    };
    const onAlert = (type) => {
      let title = type === "success" ? t("ALERT.SUCCESS") : t("ALERT.ERROR");
      Swal.fire({
        title: title,
        text: t("ALERT.UPDATED", { msg: title }),
        icon: type,
        showConfirmButton: true,
        confirmButtonColor: "#fd7e14",
        // confirmButtonClass: "btn btn-block btn-main shadow text-white my-2",
        confirmButtonText: "關閉",
        timer: 2000,
      });
    };

    return {
      currentUser,
      isUpdating,
      updatedData,
      defaultAddress,
      schema,
      myFormRef,
      firstBirthday,
      setProfileSelect,
      onSubmit,
      onAlert,
      handleGoHome,
      clearUpdatedProfile: () => store.dispatch("member/clearUpdatedProfile"),
    };
  },
  watch: {
    updatedData: async function () {
      if (this.updatedData !== null && !this.isUpdating) {
        if (this.updatedData.is_updated) {
          await this.onAlert("success");
          window.location.reload();
        } else {
          this.onAlert("error");
        }
        this.clearUpdatedProfile();
        // this.updatedData.is_updated === true
        //   ? this.onAlert("success")
        //   : this.onAlert("error");
        // this.clearUpdatedProfile();
      }
    },
  },
};
</script>
